/*
 * Get the color from the $colors map
 */
@function getColor($name) {
	
	// Check if the color has a reference
	@if map_has_key($color-references, $name) {
		// The color has a reference
		@return getColor(map_get($color-references, $name));
	}
	
	// Try to get the color
	@if map_has_key($colors, $name) {
		@return map_get($colors, $name);
	} @else {
		@error "The color '" + $name + "' does not exists in the $colors map.";
	}
	
}