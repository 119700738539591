/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
    url('#{$fa-font-path}/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}
/* CSS Document */
@font-face {
    font-family: 'sf_cartoonist_handbold';
    src: url('../fonts/SF_Cartoonist_Hand_Bold-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand_Bold-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand_Bold-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand_Bold-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand_Bold-webfont.svg#sf_cartoonist_handbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sf_cartoonist_handbold_italic';
    src: url('../fonts/SF_Cartoonist_Hand_Bold_Italic-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand_Bold_Italic-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand_Bold_Italic-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand_Bold_Italic-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand_Bold_Italic-webfont.svg#sf_cartoonist_handbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sf_cartoonist_handitalic';
    src: url('../fonts/SF_Cartoonist_Hand_Italic-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand_Italic-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand_Italic-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand_Italic-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand_Italic-webfont.svg#sf_cartoonist_handitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sf_cartoonist_handregular';
    src: url('../fonts/SF_Cartoonist_Hand-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand-webfont.svg#sf_cartoonist_handregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sf_cartoonist_hand_scbold';
    src: url('../fonts/SF_Cartoonist_Hand_SC_Bold-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand_SC_Bold-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand_SC_Bold-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand_SC_Bold-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand_SC_Bold-webfont.svg#sf_cartoonist_hand_scbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sf_cartoonist_hand_scBdIt';
    src: url('../fonts/SF_Cartoonist_Hand_SC_Bold_Italic-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand_SC_Bold_Italic-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand_SC_Bold_Italic-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand_SC_Bold_Italic-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand_SC_Bold_Italic-webfont.svg#sf_cartoonist_hand_scBdIt') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sf_cartoonist_hand_scitalic';
    src: url('../fonts/SF_Cartoonist_Hand_SC_Italic-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand_SC_Italic-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand_SC_Italic-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand_SC_Italic-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand_SC_Italic-webfont.svg#sf_cartoonist_hand_scitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sf_cartoonist_hand_scregular';
    src: url('../fonts/SF_Cartoonist_Hand_SC-webfont.eot');
    src: url('../fonts/SF_Cartoonist_Hand_SC-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/SF_Cartoonist_Hand_SC-webfont.woff') format('woff'),
url('../fonts/SF_Cartoonist_Hand_SC-webfont.ttf') format('truetype'),
url('../fonts/SF_Cartoonist_Hand_SC-webfont.svg#sf_cartoonist_hand_scregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

