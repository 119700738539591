/*
 * Breakpoints variables
 */
$breakpoints: (
	xs: (
		min: 0px,
		max: 479px
	),
	sm: (
		min: 480px,
		max: 767px
	),
	md: (
		min: 768px,
		max: 1023px
	),
	lg: (
		min: 1024px,
		max: 1279px
	),
	xlg: (
		min: 1280px,
		max: null
	)
);