/*
 * Calc the em and return it with the property and a fallback
 */
@mixin em($property, $values, $source) {
	$px: ();
	$em: ();

	// Loop trough the values
	@each $value in $values {
		@if $value == 0 or $value == auto {
			// Keep the value
			$px: append($px, $value);
			$em: append($em, $value);
		} @else {
			// Get the unit from the value
			$unit: unit($value);

			// Convert the value into a int (remove the unit)
			$val: parseInt($values);

			@if $unit == "px" {
				// Is px unit
				$px: append($px, $value);
				$em: append($em, ($val / $source + em));
			} @else if $unit == "em" {
				// Is rem unit
				$px: append($px, ($val * $source + px));
				$em: append($em, $value);
			} @else {
				// Is not a valid unit
				@error "Sorry but the unit " + $unit + " does not exists! Please use only px or em";
			}
		}

		// Check if the px and rem is the same value
		@if $px == $em {
			// Output the property in px for old browsers
			#{$property}: $px;
		} @else {
			#{$property}: $px; // Fallback for old browser
			#{$property}: $em;
		}
	}
}