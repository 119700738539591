/*
 * Float & Clearfix
 */
.pull-left, .float-left {
	float: left;
}

.pull-right, .float-right {
	float: right;
}

.clearfix {
	&:before, &:after {
		display: table;
		content: ' ';
	}
	&:after {
		clear: both;
	}
}

/*
 * Vertical aligns
 */
.vertical-align {
	&:before {
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		content: "";
	}
}

.vertical-align-middle {
	vertical-align: middle;
}

.vertical-align-top {
	vertical-align: top;
}

.vertical-align-bottom {
	vertical-align: bottom;
}

/*
 * Center a block
 */
.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}