/*
 * Define here the project colors
 */
$colors: (
	primary: #2196F3,
	info: #00BCD4,
	success: #4CAF50,
	warning: #FF5722,
	danger: #F44336,
	transparent: transparent,
	white: #FFF,
	black: #000, 
        green: #38d308,
        grey: #5d5d5d
);

$color-references: (
	link: primary,
	link-hover: info,
	h1: black,
	h2: h1,
	h3: h1,
	h4: h1,
	h5: h1,
	h6: h1
);