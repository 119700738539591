@mixin generate-color-helpers {
	@each $name, $color in $colors {
		.border-color-#{$name} {
			border-color: getColor($name);
		}
		
		.text-color-#{$name} {
			color: getColor($name);
		}
		
		.background-color-#{$name}, .bg-color-#{$name} {
			background-color: getColor($name);
		}
	}
}