/*
 * Container
 */
.container {
	padding-left: $grid-container-spacing;
	padding-right: $grid-container-spacing;
	@extend .center-block;
	
	@include responsive-only(xs) {
		width: $grid-container-width-xs;
	}
	@include responsive-only(sm) {
		width: $grid-container-width-sm;
	}
	@include responsive-only(md) {
		width: $grid-container-width-md;
	}
	@include responsive-only(lg) {
		width: $grid-container-width-lg;
	}
	@include responsive-only(xlg) {
		width: $grid-container-width-xlg;
	}
}

/*
 * Row
 */
.row {
	position: relative;
	margin-left: -#{$grid-row-spacing};
	margin-right: -#{$grid-row-spacing};
	@extend .clearfix;
	
	// No spacing option
	&.no-space, &.no-spacing {
		margin-left: 0;
		margin-right: 0;
	}
}

/*
 * Columns
 */
[class*="col-"] {
	position: relative;
	float: left;
	min-height: 1px;
	padding-left: $grid-col-spacing;
	padding-right: $grid-col-spacing;
	
	// No spacing option
	&.no-space, &.no-spacing {
		margin-left: 0;
		margin-right: 0;
	}
}

@include generate-grid;