/*
 * Vendors
 */
@import "vendors/normalize-3.0/normalize";
@import "vendors/font-awesome-4.6.3/font-awesome";

/*
 * Fonts (Google Fonts or a custom _font.scss)
 */

/*
 * Utilities
 */
@import "utils/variables";
@import "utils/functions";
@import "utils/mixins";
@import "utils/helper";

/*
 * Base
 */
@import "base/keyframes";
@import "base/all";
@import "base/typography";

/*
 * Components
 */

/*
 * Layout
 */
@import "layout/grid";
@import "layout/page";
@import "layout/header";
@import "layout/footer";

/*
 * Pages
 */


@warn "Thanks for using the template. Version: " + $project-version;