/*
 * Padding and margin
 */
@include generate-margin-and-padding;

/*
 * Width and height
 */
@include generate-width-and-height;

/*
 * Positions
 */
.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.position-fixed {
	position: fixed;
}