/*
 * Link
 */
a {
	color: getColor(link);
	text-decoration: none;
	outline: none;
	transition: color .3s linear;
	&:hover {
		color: getColor(link-hover);
	}
}

/*
 * Paragraph
 */
p {
	font-family: $font-paragraph;
}

/*
 * Headings
 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $font-headings;
}

h1, .h1 {
	color: getColor(h1);
	font-size: $size-heading-1;
}

h2, .h2 {
	color: getColor(h2);
	font-size: $size-heading-2;
}

h3, .h3 {
	color: getColor(h3);
	font-size: $size-heading-3;
}

h4, .h4 {
	color: getColor(h4);
	font-size: $size-heading-4;
}

h5, .h5 {
	color: getColor(h5);
	font-size: $size-heading-5;
}

h6, .h6 {
	color: getColor(h6);
	font-size: $size-heading-6;
}