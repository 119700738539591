/*
 * Here are the grid settings
 */
$grid-num-cols: 12;

$grid-container-spacing: 15px;
$grid-row-spacing: $grid-container-spacing;
$grid-col-spacing: $grid-row-spacing;

// Boxed layout
$grid-container-width-xs: auto;
$grid-container-width-sm: auto;
$grid-container-width-md: 750px;
$grid-container-width-lg: 970px;
$grid-container-width-xlg: 1170px;

// Fluent layout
/*
$grid-container-width-xs: 100%;
$grid-container-width-sm: $grid-container-width-xs;
$grid-container-width-md: $grid-container-width-xs;
$grid-container-width-lg: $grid-container-width-xs;
$grid-container-width-xlg: $grid-container-width-xs;
*/