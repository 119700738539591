ul, ol {
	&.list-disc {
		list-style-type: disc;
	}
	
	&.list-armenian {
		list-style-type: armenian;
	}
	
	&.list-circle {
		list-style-type: circle;
	}
	
	&.list-cjk-ideographic {
		list-style-type: cjk-ideographic;
	}
	
	&.list-decimal {
		list-style-type: decimal;
	}
	
	&.list-decimal-leading-zero {
		list-style-type: decimal-leading-zero;
	}
	
	&.list-georgian {
		list-style-type: georgian;
	}
	
	&.list-hebrew {
		list-style-type: hebrew;
	}
	
	&.list-hiragana {
		list-style-type: hiragana;
	}
	
	&.list-hiragana-iroha {
		list-style-type: hiragana-iroha;
	}
	
	&.list-katakana {
		list-style-type: katakana;
	}
	
	&.list-katakana-iroha {
		list-style-type: katakana-iroha;
	}
	
	&.list-lower-alpha {
		list-style-type: lower-alpha;
	}
	
	&.list-lower-greek {
		list-style-type: lower-greek;
	}
	
	&.list-lower-latin {
		list-style-type: lower-latin;
	}
	
	&.list-lower-roman {
		list-style-type: lower-roman;
	}
	
	&.list-none {
		list-style-type: none;
	}
	
	&.list-square {
		list-style-type: square;
	}
	
	&.list-upper-alpha {
		list-style-type: upper-alpha;
	}
	
	&.list-upper-latin {
		list-style-type: upper-latin;
	}
	
	&.list-upper-roman {
		list-style-type: upper-roman;
	}
}