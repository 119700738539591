/*
 * Calc the rem and return it with the property and a fallback
 */
@mixin rem($property, $values, $source: $size-root) {
	$px: ();
	$rem: ();
	
	$source: parseInt($source);
	
	// Loop trough the values
	@each $value in $values {
		@if $value == 0 or $value == auto {
			// Keep the value
			$px: append($px, $value);
			$rem: append($rem, $value);
		} @else {
			// Get the unit from the value
			$unit: unit($value);
			
			// Convert the value into a int (remove the unit)
			$val: parseInt($values);
			
			@if $unit == "px" {
				// Is px unit
				$px: append($px, $value);
				$rem: append($rem, ($val / $source + rem));
			} @else if $unit == "rem" {
				// Is rem unit
				$px: append($px, ($val * $source + px));
				$rem: append($rem, $value);
			} @else {
				// Is not a valid unit
				@error "Sorry but the unit " + $unit + " does not exists! Please use only px or rem";
			}
		}
		
		// Check if the px and rem is the same value
		@if $px == $rem {
			// Output the property in px for old browsers
			#{$property}: $px;
		} @else {
			#{$property}: $px; // Fallback for old browser
			#{$property}: $rem;
		}
	}
}