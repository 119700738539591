/*
 * Define here all fonts
 */
$font-inherit: inherit;
$font-arial: Arial, sans-serif;
$font-open-sans: "Open Sans", sans-serif;

$font-html: $font-arial;
$font-body: $font-html;

$font-headings: $font-arial;
$font-paragraph: $font-inherit;