/*
 * Image helper class
 */
img {
	&.img-responsive-width, &.img-responsive {
		max-width: 100%;
		height: auto;
	}
	&.img-responsive-height {
		max-height: 100%;
		width: auto;
	}
	&.img-rounded {
		border-radius: 3px;
	}
	&.img-circle {
		border-radius: 50%;
	}
}