/*
 * Text align
 */
.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify;
}

/*
 * Transformation
 */
.text-italic {
	font-style: italic;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-lowercase {
	text-transform: lowercase;
}

.text-capitalize {
	text-transform: capitalize;
}

/*
 * Wrapping
 */
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: normal;
	white-space: nowrap;
}

.text-break {
	hyphens: auto;
	word-wrap: break-word;
	white-space: normal;
}

.text-nowrap {
	white-space: nowrap;
}

/*
 * Font weight
 */
.font-weight-100 {
	font-weight: 100 !important;
}

.font-weight-200 {
	font-weight: 200 !important;
}

.font-weight-300 {
	font-weight: 300 !important;
}

.font-weight-400 {
	font-weight: 400 !important;
}

.font-weight-500 {
	font-weight: 500 !important;
}

.font-weight-600 {
	font-weight: 600 !important;
}

.font-weight-700 {
	font-weight: 700 !important;
}

.font-weight-800 {
	font-weight: 800 !important;
}

.font-weight-900 {
	font-weight: 900 !important;
}

.font-weight-unset {
	font-weight: unset;
}

/*
 * Generate font sizes
 */
@include generate-font-sizes;