/*
 * Generate the helper classes for margin and padding
 */
@mixin generate-margin-and-padding()
{
	@for $i from 0 to ($generator-max-loop-margin-and-padding + 1) {
		$properties: (margin, padding);
		
		$size: $i * $generator-multiplier-margin-and-padding;
		$size-class : parseInt($size);
		
		// Loop trough the properties
		@each $property in $properties {
			// Loop through breakpoints
			@each $bp, $val in $breakpoints {
				@include responsive-only($bp) {
					.#{$property}-#{$bp}-#{$size-class} {
						#{$property}: $size !important;
					}
					.#{$property}-#{$bp}-vertical-#{$size-class} {
						#{$property}-top: $size !important;
						#{$property}-bottom: $size !important;
					}
					.#{$property}-#{$bp}-horizontal-#{$size-class} {
						#{$property}-left: $size !important;
						#{$property}-right: $size !important;
					}
					.#{$property}-#{$bp}-top-#{$size-class} {
						#{$property}-top: $size !important;
					}
					.#{$property}-#{$bp}-left-#{$size-class} {
						#{$property}-left: $size !important;
					}
					.#{$property}-#{$bp}-right-#{$size-class} {
						#{$property}-right: $size !important;
					}
					.#{$property}-#{$bp}-bottom-#{$size-class} {
						#{$property}-bottom: $size !important;
					}
				}
			}
			
			// For all screens
			.#{$property}-#{$size-class} {
				#{$property}: $size !important;
			}
			.#{$property}-vertical-#{$size-class} {
				#{$property}-top: $size !important;
				#{$property}-bottom: $size !important;
			}
			.#{$property}-horizontal-#{$size-class} {
				#{$property}-left: $size !important;
				#{$property}-right: $size !important;
			}
			.#{$property}-top-#{$size-class} {
				#{$property}-top: $size !important;
			}
			.#{$property}-left-#{$size-class} {
				#{$property}-left: $size !important;
			}
			.#{$property}-right-#{$size-class} {
				#{$property}-right: $size !important;
			}
			.#{$property}-bottom-#{$size-class} {
				#{$property}-bottom: $size !important;
			}
		}
	}
}