/*
 * Generate the helper classes for width and height
 */
@mixin generate-width-and-height() {
	@for $i from 0 to ($generator-max-loop-width-and-height + 1) {
		$properties: (width, height);
		
		$size: $i * $generator-multiplier-width-and-height;
		$size-class: parseInt($size);
		
		// Loop trough the properties
		@each $property in $properties {
			// Loop through breakpoints
			@each $bp, $val in $breakpoints {
				@include responsive-only($bp) {
					.#{$property}-#{$bp}-#{$size-class} {
						#{$property}: $size !important;
					}
				}
			}
			
			// For all screens
			.#{$property}-#{$size-class} {
				#{$property}: $size !important;
			}
		}
	}
}