/*
 * Get the breakpoint from the breakpoint map
 */
@function getBreakpoint($point) {
	
	@if map_has_key($breakpoints, $point) {
		@return map_get($breakpoints, $point);
	} @else {
		@error "The breakpoint '" + $point + "' does not exists in the $breakpoints map.";
	}
	
}