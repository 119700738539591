html,
body {
    width: 100%;
    height: 100%;
}

#page {
    position: relative;
    width: 100%;
    min-height: 100%;  

    #construction_page {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -187.5px;
        margin-top: -50px;
        width: 375px;
        height: 100px;

        #logo_icon {
            width: 100px;
            height: 100px;  
            margin-right: 10px;
            vertical-align: top;
            display: inline-block;
        }   
        
        .svg {
            path {
                fill: getColor(grey);
            } 
        }

        label {
            font-family: 'sf_cartoonist_handregular';
            font-weight: normal;
            font-style: normal;
            font-size: 70px;
            color: getColor(grey);
            display: inline-block;
            margin-top: 12px;
        }  
        
        .sub_headline {
            position: relative;
            right: 10px;
            top: -5px;
            float: right;
            font-family: 'sf_cartoonist_hand_scregular';
            font-weight: normal;
            font-style: normal;
            font-size: 17px;
        }
        
    }
}