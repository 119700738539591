/*
 * Generate the helper classes for font-size
 */
@mixin generate-font-sizes()
{
	@for $i from 9 to ($generator-max-font-sizes + 1) {
		.font-size-#{$i} {
			font-size: $i + px;
		}
	}
}