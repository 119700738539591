/*
 * Display
 */
.display-block {
	display: block;
}

.display-inline {
	display: inline;
}

.display-inline-block {
	display: inline-block;
}

.display-table {
	display: table;
}

.display-table-row {
	display: table-row;
}

.display-table-cell {
	display: table-cell;
}

/*
 * Cursor
 */
.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

/*
 * Visibility
 */
@each $bp, $val in $breakpoints {
	@include responsive-only($bp) {
		.hidden-#{$bp} {
			display: none !important;
		}
		.visible-#{$bp} {
			display: inherit;
		}
	}
}

@include responsive-print{
	.hidden-print {
		display: none !important;
	}
	.visible-print {
		display: inherit;
	}
}

.visible {
	display: inherit;
}

.hide, .hidden {
	display: none;
}