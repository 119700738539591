/*
 * Get the value from a multi level map
 */
@function getMultiMapValue($map, $key) {
	$keys: strExplode($key, ".");
	
	// Loop through the keys
	@each $k in $keys {
		@if map_has_key($map, $k) {
			$map: map_get($map, $k);
		} @else {
			@error "Sorry but the key '" + $k + "' from '" + $key + "' does not exists in the map '" + $map + "'";
		}
	}
	
	// Return the value
	@return $map;
}