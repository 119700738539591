/*
 * Generate the @media screen
 */
@mixin generate-media-query($from, $to: null) {
	
	@if $to == null {
		@media screen and (min-width: $from) {
			@content;
		}
	} @else if $from == null {
		@media screen and (max-width: $to) {
			@content;
		}
	} @else {
		@media screen and (min-width: $from) and (max-width: $to) {
			@content;
		}
	}
	
}

@mixin generate-media-query-print() {
	@media print {
		@content
	}
}

@mixin responsive-print() {
	@include generate-media-query-print{
		@content
	}
}

@mixin responsive-only($point) {
	$size: getBreakpoint($point);
	@include generate-media-query(map_get($size, min), map_get($size, max)) {
		@content
	}
}

@mixin responsive-from($point) {
	$size: getBreakpoint($point);
	@include generate-media-query(map_get($size, min)) {
		@content
	}
}

@mixin responsive-until($point) {
	$size: getBreakpoint($point);
	@include generate-media-query(null, map_get($size, max)) {
		@content
	}
}