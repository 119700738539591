/*
 * Get the z-index level from the z-index map
 */
@function getZIndex($name) {
	
	@if map_has_key($z-indexes, $name) {
		@return map_get($z-indexes, $name);
	} @else {
		@error "The z-index '" + $name + "' does not exists in the $z-indexes map.";
	}
	
}