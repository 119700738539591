/*
 * Define here your font sizes for the project
 */
$size-root: 14px;
$size-page: $size-root;
$size-paragraph: $size-root;

$size-heading-1: 50px;
$size-heading-2: 42px;
$size-heading-3: 36px;
$size-heading-4: 28px;
$size-heading-5: 21px;
$size-heading-6: 18px;