/*
 * Generate the grid system
 */
@mixin generate-grid() {
	@each $bp, $value in $breakpoints {
		@include responsive-from($bp) {
			@for $i from 1 through $grid-num-cols {
				$width: (100% / $grid-num-cols * $i);
				
				.col-#{$bp}-#{$i} {
					width: $width;
				}
				.col-#{$bp}-offset-#{$i} {
					margin-left: $width;
				}
				.col-#{$bp}-pull-#{$i} {
					right: $width;
				}
			}
		}
	}
	
	@for $i from 1 through $grid-num-cols {
		$width: (100% / $grid-num-cols * $i);
		
		.col-print-#{$i} {
			width: $width;
		}
		.col-print-offset-#{$i} {
			margin-left: $width;
		}
		.col-print-pull-#{$i} {
			right: $width;
		}
	}
	
	@for $i from 1 through $grid-num-cols {
		$width: (100% / $grid-num-cols * $i);
		
		.col-#{$i} {
			width: $width;
		}
		.col-offset-#{$i} {
			margin-left: $width;
		}
		.col-pull-#{$i} {
			right: $width;
		}
	}
}